import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  CategoryContainer,
  Layout,
  NewProgressCard,
  NewProgressContent,
  NewProgressPoints,
  ProgressBar,
  MainProgressBar,
  PointContent,
  ImageBar,
  ImageTag,
  BronzeImg,
  SilverImg,
  GoldImg,
  FirstClass,
  SecondClass,
  ThirdClass,
  FourClass,
  Bronze,
  Silver,
  Gold,
  Finished,
} from "./newRewardDataStyles";
import { ImageUrl } from "../../utils/constants";
import { commaSeperator, findMonth } from "../../utils/methods";
import { Waiting } from "../../components";
import {
  getUserRewardDetails,
  getCompanyRewardTierAPI,
} from "../../redux/actions";
import { withTranslation } from "react-i18next";
import NewRewardCatagory from "./NewRewardCatagory";
import _ from "lodash";
import LazyImage from "../../components/common/LazyImage/LazyImage";

class NewRewardsData extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      firstIndex: 0,
      secondIndex: 0
    }
  }

  componentDidMount() {
    this.setState({
      firstIndex: 10,
      secondIndex: 5,
    });
    const { getUserRewardDetails, getCompanyRewardTierAPI, companyId } = this.props;
    getUserRewardDetails();
    getCompanyRewardTierAPI(companyId);
  }

  render() {
    const { reward, fitnessDetails, socialDetails, wellnessDetails, educationDetails, bonusWellness, bonusSocial, bonusEducation, bonusFitness,t, companyRewardsBarValues} = this.props;
    if(!reward || _.isUndefined(companyRewardsBarValues)) {
      return <Waiting />
    }
    let finishPoint = companyRewardsBarValues.finished;
    const bronzePoints = !_.isUndefined(companyRewardsBarValues.bronze)?companyRewardsBarValues.bronze:0;
    const silverPoints = !_.isUndefined(companyRewardsBarValues.silver)?companyRewardsBarValues.silver:0;
    const goldPoints = !_.isUndefined(companyRewardsBarValues.gold)?companyRewardsBarValues.gold:0;
    const bronze =  bronzePoints/finishPoint*100;
    const silver = silverPoints/finishPoint*100;
    const gold = goldPoints/finishPoint*100;
    const points = reward.total;
    const categoryArray = [
      {
        src:'Fitness_points_bg',
        title: 'Fitness Points',
        availalePoint: reward.max_fitness,
        earnPoint: reward.fitness_point,
        percentage: reward.fitness_percentage,
        maxAvailableFitnessPoint: reward.max_fitness_points || 0,
        details: fitnessDetails || [],
        bonus: bonusFitness,
        key:"fitness"
      },
      {
        src: 'Social_Points_bg',
        title: 'Social Points',
        availalePoint: reward.max_social,
        earnPoint: reward.social_point,
        percentage: reward.social_percentage,
        details: socialDetails || [],
        bonus: bonusSocial,
        key:"social"
      },
      {
        src: 'healthAssessment_points_bg',
        title: 'Health Assessment Points',
        availalePoint: reward.max_wellness,
        earnPoint: reward.wellness_point,
        percentage: reward.wellness_percentage,
        details: wellnessDetails || [],
        bonus: bonusWellness,
        key:"hdm"
      },
      {
        src: 'Wellness_points_bg',
        title: 'Wellness Points',
        availalePoint: reward.max_education,
        earnPoint: reward.education_point,
        percentage: reward.education_percentage,
        details: educationDetails || [],
        bonus: bonusEducation,
        key:"education"
      }
    ]
    const {  monthlyRewardPoint } = this.props;
    const date = new Date();
    const month =  findMonth(date.getMonth());
    let progressData = {};
    progressData.point = reward.total === finishPoint ? finishPoint : reward.total > finishPoint ? finishPoint : reward.total >= goldPoints+1 ? finishPoint-reward.total : reward.total >= silverPoints+1 ? (goldPoints)-reward.total : reward.total < bronzePoints ? bronzePoints-reward.total : (silverPoints)-reward.total;
    progressData.level = reward.total >finishPoint ? '' : reward.total < bronzePoints ? 'bronze' : reward.total < silverPoints ? 'silver' :  reward.total < goldPoints ? 'gold' : reward.total <  finishPoint ? 'Finished' : reward.total ===  finishPoint ? 'Finished' : '';
    return (
      <div>
        <Layout>
          <NewProgressCard >
            <NewProgressContent>
              <div className='main_content'>
                { reward.total >= 1 &&
              <div className="newImageData">
                <ImageTag>
                  <LazyImage src={reward.total > goldPoints ? `${ImageUrl}/images/NewRewardSection/gold_bg_img.png` : reward.total > silverPoints ? `${ImageUrl}/images/NewRewardSection/slivers_bg_img.png` :  reward.total > bronzePoints ? `${ImageUrl}/images/NewRewardSection/bronze_bg_img.png` : `${ImageUrl}/images/NewRewardSection/no_BadgeImg.png`}/>
                </ImageTag>
              </div> 
                }
                <div className='main_point_class'>
                  <div className='point_class'>
                    <div className='total_value'>{t("Total Points")}</div>
                    <div className='total_point_value'>{commaSeperator(reward.total)}</div>
                    <div className='left_point'>{ reward.total <= finishPoint && <div className="inline"> <span className='point'>{ reward.total === finishPoint ? '' : commaSeperator(progressData.point)}</span> <span className='point_reach'>{reward.total === finishPoint ? '':progressData.point > 1 ? t('points left to reach') : t('point left to reach  ') }</span> <span className='level'>{t(progressData.level)}</span></div> }</div>
                    <div className='border_line' />
                    <div className='left_point'>{monthlyRewardPoint} <span className='earn_point'>{t("points earned in")}</span> {t(month)}</div>
                  </div>
                </div>
              </div>
            </NewProgressContent>
            <NewProgressPoints>
              <MainProgressBar>
                <ProgressBar width={points <= finishPoint ? points*100/finishPoint : 100} color={points > silverPoints ? "#DCC34C" : points > bronzePoints ? "#B9C1C6" : "#A57576"}>
                  <div className="progrss" />
                  <BronzeImg width={ points > bronzePoints ? '100%' : '100%'}  imageWidth={ (points > bronzePoints+1)||(points === bronzePoints) ? '1' : ''}    left={ points < bronzePoints ? `calc(${bronze}% - 38px)` : `calc(${bronze}% - 60px)`} top={points < bronzePoints ? '-65px' : '-77px'}>
                    <Bronze paddingLeft={points < bronzePoints ? '-5px' : '5px'}  paddingBottom={points < bronzePoints ? '10px' : '6px'}>{t("Bronze")}</Bronze>
                    <LazyImage src={`${ImageUrl}/images/Rewards/bronze.png`}/>
                    <FirstClass marginTop={ points < bronzePoints ? '10px' : '5px'} paddingLeft={points < bronzePoints ? 'none' : '2px'} marginLeft={points < bronzePoints ? '-5px' : 'none'}>{commaSeperator(bronzePoints)}{("pts")}</FirstClass>
                  </BronzeImg>
                  <SilverImg  width={ points > silverPoints ? '100%' : '100%'}  imageWidth ={ (points > silverPoints+1)||(points === silverPoints) ? '1' : ''}  left={ points < silverPoints ? `calc(${silver}% - 35px)` : `calc(${silver}% - 60px)`} top={points < silverPoints ? '-190px' : '-201px'}>
                    <Silver paddingLeft={points < silverPoints ? '2px' : '10px'}  paddingBottom={points < silverPoints ? '10px' : '6px'}>{t("Silver")}</Silver>
                    <LazyImage src={`${ImageUrl}/images/Rewards/silver.png`} />
                    <SecondClass  marginTop={ points < silverPoints ? '12px' : '8px'} paddingLeft={points < silverPoints ? 'none' : '2px'} marginLeft={points < silverPoints ? '-5px' : 'none'}>{commaSeperator(silverPoints)}{("pts")}</SecondClass>
                  </SilverImg>
                  <GoldImg  width={ points> goldPoints ? '100%' : '100%'}  imageWidth ={ (points > goldPoints+1)||(points === goldPoints) ? '1' : ''}   left={ points < goldPoints ? `calc(${gold}% - 35px)` : `calc(${gold}% - 60px)`} top={points < goldPoints ? '-314px' : '-324px'}>
                    <Gold paddingLeft={points < goldPoints ? '2px' : '10px'} paddingBottom={points < goldPoints ? '10px' : '6px'}>{t("Gold")}</Gold>
                    <LazyImage src={`${ImageUrl}/images/Rewards/gold.png`} />
                    <ThirdClass  marginTop={ points < goldPoints ? '10px' : '5px'  }  paddingLeft={points < goldPoints ? 'none' : '2px'} marginLeft={points < goldPoints ? '-5px' : 'none'}>{commaSeperator(goldPoints)}{("pts")}</ThirdClass>
                  </GoldImg>
                  <ImageBar>
                    <div className='flag_img'>
                      <Finished>{t("Finish")}</Finished>
                      <LazyImage src={`${ImageUrl}/images/NewRewardSection/finish-flag.png`} />
                      <FourClass>{commaSeperator(companyRewardsBarValues.finished) + "pts"}</FourClass>
                    </div>
                  </ImageBar>
                </ProgressBar>
              </MainProgressBar>
              <PointContent />
            </NewProgressPoints>
          </NewProgressCard>
          <CategoryContainer>
            { categoryArray.map((category, index) => (
              <>
                {(!this.props.companyBranding?.company_branding?.social_feed && category?.key === "social" || !this.props.companyBranding?.company_branding?.show_biometric && category?.key === "hdm"|| !this.props.companyBranding?.company_branding?.show_education && category?.key === "education")? null : <NewRewardCatagory category={category} key={index} history={this.props.history} monthlyRewardPoint={this.props.monthlyRewardPoint}  getIndex={index}/>}
              </>
            ))}
          </CategoryContainer>   
        </Layout>
      </div>
    );
  }
}

NewRewardsData.propTypes = {
  reward: PropTypes.object,
  monthlyRewardPoint: PropTypes.number,
  fitnessDetails: PropTypes.array,
  socialDetails: PropTypes.array,
  wellnessDetails: PropTypes.array,
  educationDetails: PropTypes.array,
  bonusWellness: PropTypes.array,
  bonusSocial: PropTypes.array,
  getUserRewardDetails: PropTypes.func,
  history: PropTypes.object,
  bonusFitness: PropTypes.array,
  bonusEducation: PropTypes.array,
  t: PropTypes.func,
  getCompanyRewardTierAPI: PropTypes.func,
  companyRewardsBarValues: PropTypes.array,
  companyId: PropTypes.number,
  userId: PropTypes.number,
  getHistoryRewardPoints: PropTypes.func,
  rewardData: PropTypes.array,
  companyBranding: PropTypes.object,
};

const mapStateToProps = (state) => ({
  reward: state.wellnessDashboard.reward,
  monthlyRewardPoint: state.wellnessDashboard.monthlyRewardPoint,
  fitnessDetails: state.wellnessDashboard.fitnessDetails,
  socialDetails: state.wellnessDashboard.socialDetails,
  wellnessDetails: state.wellnessDashboard.wellnessDetails,
  educationDetails: state.wellnessDashboard.educationDetails,
  bonusWellness: state.wellnessDashboard.bonusWellness,
  bonusSocial: state.wellnessDashboard.bonusSocial,
  bonusFitness: state.wellnessDashboard.bonusFitness,
  bonusEducation: state.wellnessDashboard.bonusEducation,
  companyRewardsBarValues: state.wellnessDashboard.companyRewardsBarValues,
  userId: state.profileData.userId,
  rewardData: state.wellnessDashboard.rewardData,
  companyBranding: state.wellnessDashboard.companyBrandingDetails,
});

const mapDispathcToProps = (dispatch) => ({
  getUserRewardDetails: () => dispatch(getUserRewardDetails()),
  getCompanyRewardTierAPI: (companyId) => dispatch(getCompanyRewardTierAPI(companyId))
})

export default connect(mapStateToProps, mapDispathcToProps)(withTranslation()(NewRewardsData));